import styles from "../styles/informasjon.module.scss";
import sporten from "../bilder/sporten.png";
import { Header } from "../components/header";
import { Separator } from "../components/separator";
import { H2Header } from "../components/h2Header";
import React from "react";

export default function Informasjon() {
  return (
    <>
      <Header>Informasjon</Header>
      <div className={styles.informasjon_bolk}>
        <H2Header>Når</H2Header>
        {/*TODO fiks dette når vi vet hva som egentlig skjer? */}
        <h3 className={styles.informasjon_h3}>Fredag 6. september</h3>
        <p>Middag og quiz på Voksenåsen Hotell fra kl 18.00.</p>
        <p>
          Vi håper dere har lyst og mulighet til å være med på en felles middag
          og quiz sammen på Voksenåsen Hotell kl. 18.00 på fredag 6. september.
          Middagen er valgfri og koster ca 300 kr per person for pizzabuffet.
          Dersom du ikke ønsker å delta på middagen kan du likevel være med på
          quizen etterpå. Husk da å huke av for dette i RSVP-skjemaet.
        </p>
        <p>Antrekk: Pent/sommerlig</p>
        <Separator />

        <h3 className={styles.informasjon_h3}>Lørdag 7. september</h3>
        <p>
          Vielsen starter kl 14.00 på Sporten, etterfulgt av bryllupsmiddag og
          fest.
        </p>

        <p>Antrekk: Dress</p>

        <p>
          Alle med allergier, intoleranse eller andre matpreferanser må si ifra{" "}
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          ved å svare på "Allergier" og/eller "Matpreferanser" i svarkortet,
          eller kontakte oss direkte.
        </p>

        <p>
          Dersom du ønsker å holde en tale eller et annet innslag under middagen
          i bryllupet må du kontakte toastmasterne våre{" "}
          <a href="mailto:cecik123@hotmail.com">Cecilie</a> eller{" "}
          <a href="mailto:charlottesorum@gmail.com">Charlotte</a>.
        </p>
      </div>
      <div className={styles.informasjon_bolk}>
        <H2Header>Lokasjon</H2Header>
        <p>
          <a
            href="https://sult.no/selskapslokaler/sporten/"
            target="_blank"
            rel="noreferrer"
          >
            Sporten
          </a>{" "}
          ligger på toppen av Holmenkollen, ikke langt unna Frognerseteren.
        </p>
        <img src={sporten} alt="Sporten" className={styles.sporten_img} />
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d529.3469510820607!2d10.679983008331384!3d59.97793949763117!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464172631dbd092d%3A0xd2872ce8abc65c82!2sSporten!5e0!3m2!1sen!2sno!4v1699646124463!5m2!1sen!2sno"
          width="600"
          height="450"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="sporten"
          className={styles.sporten_kart}
        />
      </div>

      <div className={styles.informasjon_bolk}>
        <H2Header>Overnatting</H2Header>
        <p>
          Sporten har et samarbeid med{" "}
          <a href={"https://voksenaasen.no/"}>Voksenåsen Hotell</a>, som gjør at
          vi får litt billigere priser på hotellrom. Voksenåsen er i gåavstand
          fra Sporten.
        </p>
        <p>
          Alle gjester som ønsker å bo på Voksenåsen Hotell fra fredag til
          lørdag eller lørdag til søndag (eller begge) kan ringe til hotellet på
          22 81 15 00 eller sende mail til{" "}
          <a href="mailto:booking@voksenaasen.no">booking@voksenaasen.no</a> og
          oppgi koden "<b>BårdTania070924</b>", så vil de reservere et rom til
          dere. Dere må bestille rom innen 06.07.24.
        </p>
        <p>
          Priser for rom er følgende:
          <br />
          Enkeltrom 1090 kr / natt inkl. frokost
          <br />
          Dobbeltrom 1390 kr / natt inkl. frokost
        </p>
        <p>
          Innsjekk er fra kl. 15.00 ankomstdagen. Utsjekk er frem til kl. 11.00
          på avreisedagen.
        </p>
      </div>
    </>
  );
}
