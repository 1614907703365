import styles from "../styles/brudefolget.module.scss";
import BrudefolgetPerson from "../components/brudefolget-person";
import { Header } from "../components/header";
import React from "react";
import ida from "../bilder/brudefolget/Ida.jpg";
import daniella from "../bilder/brudefolget/Daniella.jpg";
import charlotte from "../bilder/brudefolget/Charlotte.png";
import cecilie from "../bilder/brudefolget/Cecilie.jpg";
import larsoyvind from "../bilder/brudefolget/LarsOyvind.png";
import matias from "../bilder/brudefolget/Matias.png";
import { H2Header } from "../components/h2Header";

export default function Brudefolget() {
  return (
    <>
      <Header>Brudefølget</Header>
      <div className={styles.personer}>
        <H2Header>Forlovere</H2Header>
        <div className={styles.personer_bolk}>
          <BrudefolgetPerson
            name="Ida Kristiansen Juklerød"
            imgSrc={ida}
            imgAlt="ida"
            text="Ida har vært Tanias bestevenn siden de møttes i Maria Terrasse som 3-åringer.
            De har gått i samme klasse både på barneskolen, ungdomsskolen og et lite sekund da de
            gikk på Sandvika Vgs før Tania byttet til Dønski Vgs, og de har spilt håndball sammen på
            Jardar gjennom alle ungdomsårene. De har også vært på utallige ferier sammen og opplevd
            mange badeland, båt-bananer, biffer og pastaer og ikke minst en nesten-kidnapping i
            Tunisia."
          />
          <BrudefolgetPerson
            name="Daniella Gulbrandsen"
            imgSrc={daniella}
            imgAlt="daniella"
            text="Daniella og Tania møttes på Dønski Vgs, men ble ikke skikkelig gode
            venner før Daniella inviterte seg selv på pizza hos Tania en ettermiddag.
            En pizza ble til mange, og de ble så godt som uatskillelige da Tania
            jobbet på Ica Maxi Vøyenenga og Daniella på Kiwi Vøyenenga med like
            timeplaner for sene og tidlige vakter, slik at de kunne spise mer pizza
            sammen."
          />
          <BrudefolgetPerson
            name="Lars Øyvind Lien"
            imgSrc={larsoyvind}
            imgAlt="lars øyvind"
            text="Lars Øyvind og Bård kjenner hverandre helt tilbake fra barneskolen hvor de sammen fant ut at alt av lekser kunne byttes
            ut med Mario Kart 64 og Rammstein på full spiker. De kom seg til slutt gjennom samtlige skoletrinn med
            ukristelig slett oppførsel og meget i sløyd. I de siste 13 år har Lars tuktet i Forsvarets mørke kriker og
            kroker, men gutta møtes i ny og ne når det er behov for å repetere gamle kunster fra Mario Kart eller
            løsne opp i fastlåste speiderknuter."
          />
          <BrudefolgetPerson
            name="Matias Gjøstein Sundal"
            imgSrc={matias}
            imgAlt="matias"
            text="Bård og Matias møttes i speideren for lenge (lenge!!!) siden. De har ikke bodd i samme by siden 2011, men har
            til tross for dette opprettholdt jevnlig kontakt med hverandre på tvers av landet. De har besøkt hverandre i Bergen,
            Voss, Oslo, Songdal, Trondheim og Svalbard. Matias (og Lars Øyvind) overtalte Bård til å bli med i DNT ung da de gikk på ungdomsskolen, hvor de sammen
            har gått på toppturer, rafteturer, hytteturer og koseturer. Ski og sykkel er noen av fellesinteressene Matias og Bård har til
            felles, til tross for at Matias har for brede dekk og for mange dempere på sykkelen sin."
          />
        </div>

        <H2Header>Toastmastere</H2Header>
        <div className={styles.personer_bolk}>
          <BrudefolgetPerson
            name="Charlotte Sørum Hansen"
            imgSrc={charlotte}
            imgAlt="charlotte"
            text="Charlotte og Tania er begge fra Bærum, og har kjent hverandre i mange år.
            Det var derimot ikke før det herrans år 2020 at de ble veldig mye bedre kjent da
            jentegjengen kjørte ukentlige samlinger på Zoom. Siden da har det vært mange hytteturer,
            fester og årlige sommerturer til Stavern. Ekstra spesielt var det at Charlotte fikk
            Wilma omtrent samtidig som Bård og Tania fikk Solo, som gjorde at de kunne dele
            erfaringer, gleder og vanskeligheter med hundene."
            extraText={
              <p>
                Dersom du ønsker å holde en tale eller et annet innslag under
                middagen i bryllupet kan du kontakte Charlotte på{" "}
                <a href="mailto:charlottesorum@gmail.com">
                  charlottesorum@gmail.com
                </a>
              </p>
            }
          />

          <BrudefolgetPerson
            name="Cecilie Elisabeth Kristensen"
            imgSrc={cecilie}
            imgAlt="cecilie"
            text="Cecilie og Tania ble godt kjent under pandemien da Cecilie studerte i England, fordi jentegjengen
            møttes til ukentlige fredagssamlinger på Zoom. Nå møtes de til stadighet på hytteturer, i festlige
            lag og årlige sommerturer til Stavern, for å nevne noe. Cecilie flyttet tilbake til Norge etter pandemien, og har
            på kort tid blitt en av Tania og Bårds nærmeste venner."
            extraText={
              <p>
                Dersom du ønsker å holde en tale eller et annet innslag under
                middagen i bryllupet kan du kontakte Cecilie på{" "}
                <a href="mailto:cecik123@hotmail.com">cecik123@hotmail.com</a>
              </p>
            }
          />
        </div>
      </div>
    </>
  );
}
