import styles from "../styles/h2header.module.scss";
import {PropsWithChildren} from "react";

export function H2Header(props: PropsWithChildren) {
  return (
    <div className={styles.divider}>
      <span></span>
      <span>
        <h2>{props.children}</h2>
      </span>
      <span></span>
    </div>
  );
}
