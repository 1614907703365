import styles from "../styles/index.module.scss";
import verdifullfoto from "../bilder/VerdifullFoto1_web.jpg";
import { Header } from "../components/header";
import React from "react";

export default function Hjem() {
  return (
    <div className={styles.mainpage_informasjon}>
      <Header>7. september 2024</Header>
      <img
        src={verdifullfoto}
        alt="Foto: VerdifullFoto"
        className={styles.mainpage_image}
        title="Foto: Verdifull Foto"
      />
    </div>
  );
}
