import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RootLayout from "./components/layout";
import Hjem from "./pages/hjem";
import Informasjon from "./pages/informasjon";
import Brudefolget from "./pages/brudefolget";
import Brudeparet from "./pages/brudeparet";
import Onskeliste from "./pages/onskeliste";
import "@navikt/ds-css";

function App() {
  return (
    <>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <RootLayout>
                  <Hjem />
                </RootLayout>
              }
            />

            <Route
              path="/informasjon"
              element={
                <RootLayout>
                  <Informasjon />
                </RootLayout>
              }
            />

            <Route
              path="/brudeparet"
              element={
                <RootLayout>
                  <Brudeparet />
                </RootLayout>
              }
            />

            <Route
              path="/brudefolget"
              element={
                <RootLayout>
                  <Brudefolget />
                </RootLayout>
              }
            />

            <Route
              path="/onskeliste"
              element={
                <RootLayout>
                  <Onskeliste />
                </RootLayout>
              }
            />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
