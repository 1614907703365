import styles from "../styles/brudefolget.module.scss";
import React from "react";

interface PersonProps {
  name: string;
  imgSrc: string;
  imgAlt: string;
  text: string;
  extraText?: React.ReactNode;
}

export default function BrudefolgetPerson({
  name,
  imgSrc,
  imgAlt,
  text,
  extraText,
}: PersonProps) {
  return (
    <div>
      <h3>{name}</h3>
      <img src={imgSrc} alt={imgAlt} className={styles.image} />
      <p>{text}</p>
      {extraText}
    </div>
  );
}
