import "../styles/globals.scss";
import Navbar from "./navbar";
import { PropsWithChildren } from "react";

export default function RootLayout(props: PropsWithChildren) {
  return (
    <>
      <Navbar />
      <main>{props.children}</main>
    </>
  );
}
