"use client";
import { useEffect, useState } from "react";
import styles from "../styles/navbar.module.scss";
import classNames from "classnames";
import { Squash as Hamburger } from "hamburger-react";
import { NavLink, useLocation } from "react-router-dom";

const NAVBAR_LIST = [
  { text: "Hjem", href: "/" },
  { text: "Informasjon", href: "/informasjon" },
  { text: "Brudeparet", href: "/brudeparet" },
  { text: "Brudefølget", href: "/brudefolget" },
  { text: "Ønskeliste", href: "/onskeliste" },
];

export default function Navbar() {
  const [hamburger, setHamburger] = useState(false);
  const router = useLocation();

  const toggleHamburger = () => {
    setHamburger(!hamburger);
  };

  useEffect(() => {
    setHamburger(false);
  }, [router]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 767 && hamburger) {
        setHamburger(false);
      }
    }
    window.addEventListener("resize", handleResize);
  });

  return (
    <div className={hamburger ? styles.navbar_wrapper : ""}>
      <div onClick={toggleHamburger} className={styles.hamburger}>
        <Hamburger toggled={hamburger} toggle={toggleHamburger} />
      </div>

      <div className={hamburger ? styles.liste_wrapper : ""}>
        <ul
          className={classNames(
            hamburger ? styles.navbar_hamburger : "",
            styles.navbar,
          )}
        >
          {NAVBAR_LIST.map((element, index) => {
            return (
              <li key={index} className={styles.navbar_element}>
                <NavLink
                  to={element.href}
                  className={
                    router.pathname === element.href
                      ? styles.navbar_element_aktiv
                      : ""
                  }
                >
                  {element.text}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
