import styles from "../styles/brudeparet.module.scss";
import React from "react";
import { Header } from "../components/header";
import tardUng from "../bilder/TardUng.jpg";
import tard from "../bilder/Tard.jpg";
import starwars from "../bilder/TardStarWars.jpg";
import tardJul from "../bilder/TardJul.jpg";
import classNames from "classnames";
import bardogtania from "../bilder/VerdifullFoto2_web.jpg";

export default function Brudeparet() {
  return (
    <>
      <Header>Brudeparet</Header>
      <div className={styles.brudeparet}>
        <img
            src={bardogtania}
            alt="Bård og Tania"
            className={styles.brudeparet_bilde_main}
        />
        Foto: Verdifull foto
        <img
            src={tard}
            alt="Tania og Bård"
            className={styles.brudeparet_bilde}
        />

        <p>
          Bård og Tania møttes i Trondheim i 2015 etter at Tania flyttet inn i
          kollektivet Bård bodde i Eirik Jarls Gate 2. Det var kjærlighet ved
          første blikk for Tania, men for Bård tok det noen uker før han også
          var hekta. De har stort sett bodd sammen siden det, også da Bård bodde
          med Didrik og Alex som gjestet Tania hele tiden. Ett unntak i
          bolig-streaken var da Bård våren 2019 bodde i Bærum hos foreldrene til
          Tania da han fikk jobb for Siemens i Oslo, mens Tania gjorde seg
          ferdig med sin bachelorgrad i Trondheim.
        </p>
        <img
            src={tardUng}
            alt="Tania og Bård"
            className={styles.brudeparet_bilde}
        />
        <p>
          Høsten 2019 kjøpte de sin første leilighet sammen på Tveita i Oslo. En
          halv pandemi senere flyttet også Solo inn, og Bård og Tania hadde
          plutselig en fulltidshobby som førte dem enda nærmere hverandre, med
          blant annet hundekurs og faste kveldsturer sammen hver kveld. I
          sommeren 2023 ble de til og med besteforeldre da Solo fikk valper!
        </p>
        <img
            src={starwars}
            alt="Star Wars"
            className={classNames(styles.brudeparet_bilde, styles.star_wars)}
        />
        <p>
          Sommeren 2022 reiste de på ferie til Ischia, og lite visste Tania at
          Bård bar på en stor hemmelighet han var rimelig stresset over skulle
          bli oppdaget i sikkerhetskontrollen. På en av de vakreste dagene
          bestemte Bård seg for å fri, men Tania hadde feber (antagelig corona,
          men det ville vi ikke si til noen i Italia av åpenbare grunner), så
          han utsatte frieriet. På en av de andre vakreste dagene som vi delte
          med Kristina og Eirik som var på bryllupsreise i Italia, tok vi en
          båttur rundt øya, og inne i en liten grønn grotte (La Grotta Verde)
          stilte Bård et spørsmål han aldri kunne trekke tilbake. Tania sa
          selvfølgelig ja (etter en haug av spørsmål om han tullet eller mente
          det, samtidig som klaustrofobien meldte seg for alvor inne i den
          trange grotten), før de svømte ut av grotten sammen med Eirik som
          hadde vitnet det hele.
        </p>

        <img
            src={tardJul}
            alt="Tania og Bård jul"
            className={styles.brudeparet_bilde}
        />
      </div>
    </>
  );
}
