import styles from "../styles/header.module.scss";
import React from "react";
import blomster from "../bilder/pynt/blomster.png";
import blomsterLiggende from "../bilder/pynt/blomster_ligg.png";

interface Props {
  children: React.ReactNode;
  storBlomst?: boolean;
}

export function Header({ children, storBlomst = false }: Props) {
  return (
    <div className={styles.header}>
      <h1>{children}</h1>
      {storBlomst ? (
        <img src={blomster} alt="logo" className={styles.header_img} />
      ) : (
        <img src={blomsterLiggende} alt="logo" className={styles.header_img} />
      )}
    </div>
  );
}
