import { Header } from "../components/header";
import styles from "../styles/onskeliste.module.scss";
import { Separator } from "../components/separator";

export default function Onskeliste() {
  return (
    <>
      <Header>Ønskeliste</Header>
      <div>
        <p>
          Vi har bodd sammen siden 2017, og har derfor det meste vi trenger av
          glass og porselen. Vi har allikevel satt opp noen ønsker her:{" "}
          <ul className={styles.onskeliste}>
            <li>
              <a
                href="https://onskelister.no/liste?id=nCXJrD9yFXMovVUsL269"
                target="_blank"
                rel="noreferrer"
              >
                Ønskeliste
              </a>
            </li>
          </ul>
        </p>

        <Separator />

        <p>
          Dersom du heller ønsker å gi oss et pengebidrag på vår store dag har
          vi flere ønsker til diverse fremtidsplaner! Alle bidrag kan overføres
          til kontonr. <strong>8398.12.23449</strong>, tilhørende den kategorien
          du ønsker å støtte:
        </p>

        <ul className={styles.onskeliste_liste}>
          <li>
            Vi skal på bryllupsreise til Japan våren 2025. Vi ønsker en
            skikkelig opplevelsesreise, og setter derfor stor pris på alle
            bidrag! Om du ønsker å bidra til at vår bryllupsreise blir
            uforglemmelig, kan du merke overføringen med{" "}
            <strong>
              <i>Bryllupsreise</i>
            </strong>
            .
          </li>
          <br />
          <li>
            Vi kommer (dessverre) ikke til å bo på Tveita for alltid, så her kan
            du hjelpe til å realisere boligdrømmen vår! Merk overføringen med{" "}
            <strong>
              <i>Boligdrømmen</i>
            </strong>
            .
          </li>
          <br />
          <li>
            Fremtiden er skummel, og det er alltid fint å ha et sparefond for a
            rainy day! Merk overføringen med{" "}
            <strong>
              <i>Fondssparing</i>
            </strong>
            .
          </li>
          <br />
          <li>
            Vi er veldig glade i å gå ut og spise og se på standup, men det er
            sjelden vi tar oss råd til å gjøre det. Om du ønsker å bidra, kan du
            merke overføringen med{" "}
            <strong>
              <i>Date night</i>
            </strong>
            .
          </li>
          <br />
          <li>
            Vi nyter å dra på turer i skog og mark, og vi kunne trengt litt nytt
            turutstyr. Merk overføringen med{" "}
            <strong>
              <i>Turutstyr</i>
            </strong>
            .
          </li>
        </ul>

        <p>Vi setter stor pris på alle gaver!</p>
      </div>
    </>
  );
}
